import { useEffect, useRef, useState } from "react";
import "./App.css";
import MapView from "@arcgis/core/views/MapView";
import WebMap from "@arcgis/core/WebMap";
import esriConfig from "@arcgis/core/config";
import Graphic from "@arcgis/core/Graphic";
import ServiceAreaParameters from "@arcgis/core/rest/support/ServiceAreaParameters";
import FeatureSet from "@arcgis/core/rest/support/FeatureSet";
import * as networkService from "@arcgis/core/rest/networkService";
import * as serviceArea from "@arcgis/core/rest/serviceArea";
import {
  Box,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  IconButton,
  Slider,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import * as route from "@arcgis/core/rest/route.js";
import RouteParameters from "@arcgis/core/rest/support/RouteParameters";
import Collection from "@arcgis/core/core/Collection";
import Stop from "@arcgis/core/rest/support/Stop";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import * as geometryEngine from "@arcgis/core/geometry/geometryEngine";
import LayerList from "@arcgis/core/widgets/LayerList";
import Search from "@arcgis/core/widgets/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import UndoIcon from "@mui/icons-material/Undo";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import UploadFileIcon from "@mui/icons-material/FileUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import RouteOutlinedIcon from "@mui/icons-material/RouteOutlined";
import { apiKey, autoGenerateStop } from "./config";
import SketchViewModel from "@arcgis/core/widgets/Sketch/SketchViewModel";
import * as webMercatorUtils from "@arcgis/core/geometry/support/webMercatorUtils";
import Polygon from "@arcgis/core/geometry/Polygon";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import Point from "@arcgis/core/geometry/Point";

const serviceAreaUrl =
  "https://route-api.arcgis.com/arcgis/rest/services/World/ServiceAreas/NAServer/ServiceArea_World/solveServiceArea";
const routeUrl =
  "https://route-api.arcgis.com/arcgis/rest/services/World/Route/NAServer/Route_World";

const pointSymbol = {
  type: "simple-marker",
  style: "circle",
  color: [0, 0, 255],
  size: "13px", // pixels
  outline: {
    color: [255, 255, 0],
    width: 3,
  },
};

let symbolLine = {
  type: "simple-line",
  color: "green",
  width: "1px",
  style: "solid",
};

const symbolPolygon: any = {
  type: "simple-fill",
  color: [51, 51, 204, 0.5],
  style: "solid",
  outline: {
    color: "white",
    width: 1,
  },
};

let pointGraphic: any = {
  symbol: pointSymbol,
  geometry: null,
};

enum MAPCLICKACTION {
  None = "None",
  Pick = "Pick",
  DeleteVertices = "DeleteVertices",
}

function App() {
  useEffect(() => {
    console.log("v1.0");
  }, []);
  const [sketchVM, SetSketchVM] = useState<any>("_");
  const [view, SetView] = useState<any>("_");
  const [serviceAreaCentersGL, setServiceAreaCentersGL] =
    useState<__esri.GraphicsLayer>(
      new GraphicsLayer({ title: "Service Area Centers" })
    );
  const [initialServiceAreaGL, setinitialServiceAreaGL] =
    useState<__esri.GraphicsLayer>(
      new GraphicsLayer({ title: "Service Area - Initial", opacity: 75 })
    );
  const [routingExtentPolygonGL, setRoutingExtentPolygonGL] =
    useState<__esri.GraphicsLayer>(
      new GraphicsLayer({ title: "Service Areas - Proposed" })
    );
  const [routingPathsGL, setroutingPathsGL] = useState<__esri.GraphicsLayer>(
    new GraphicsLayer({ title: "Route Path", visible: false })
  );
  const [routingStopsGL, setRoutingStopsGL] = useState<__esri.GraphicsLayer>(
    new GraphicsLayer({ title: "Route Stops" })
  );
  const [serviceAreaCenterPoint, setServiceAreaCenterPoint] =
    useState<__esri.Point>();
  const [manualStops, setManualStops] = useState<__esri.Point[]>([]);
  const [mapclickAction, setMapclickAction] = useState<MAPCLICKACTION>(
    MAPCLICKACTION.None
  );
  const [autoGenerateStops, setAutoGenerateStops] =
    useState<boolean>(autoGenerateStop);
  const [serviceAreaSolved, setServiceAreaSolved] = useState<boolean>(false);
  const [extentTolerance, setExtentTolerance] = useState<number>(0.1);
  const [serviceAreaDrivingTime, setServiceAreaDrivingTime] =
    useState<number>(10);
  const [serviceAreaPolygons, SetServiceAreaPolygons] = useState<any[]>([]);
  const [serviceAreaIDToClip, setserviceAreaIDToClip] = useState<string>("");
  const generateStops = (extent: any) => {
    const xDiff = extent.xmax - extent.xmin;
    const yDiff = extent.ymax - extent.ymin;

    const xDelta = xDiff * extentTolerance;
    const yDelta = yDiff * extentTolerance;

    const point1 = {
      type: "point",
      x: extent.xmax - xDelta,
      y: extent.ymax - yDelta,
      spatialReference: extent.spatialReference,
    };
    const point2 = {
      type: "point",
      x: extent.xmin + xDelta,
      y: extent.ymax - yDelta,
      spatialReference: extent.spatialReference,
    };
    const point3 = {
      type: "point",
      x: extent.xmin + xDelta,
      y: extent.ymin + yDelta,
      spatialReference: extent.spatialReference,
    };
    const point4 = {
      type: "point",
      x: extent.xmax - xDelta,
      y: extent.ymin + yDelta,
      spatialReference: extent.spatialReference,
    };
    const stops = [point1, point2, point3, point4, point1];
    return stops;
  };

  const _SovleRoute = async (pStops: __esri.Point[]) => {
    const r = Math.floor(Math.random() * 200) + 55;
    const g = Math.floor(Math.random() * 200) + 55;
    const b = Math.floor(Math.random() * 200) + 55;
    pointGraphic.symbol.color = [0, 255, 0];
    pointSymbol.outline.color = [r, g, b];

    const _Stops = pStops.map((point, index) => {
      if (index > 0) {
        const graphic: any = {
          symbol: pointSymbol,
          geometry: point,
        };
        routingStopsGL.add(graphic);
      }
      return new Stop({
        geometry: {
          x: point.x,
          y: point.y,
          spatialReference: point.spatialReference,
        },
      });
    });

    const stops = new Collection(_Stops);
    const routeParams = new RouteParameters({
      apiKey: apiKey,
      stops,
      outputLines: "true-shape",
      restrictUTurns: "no-backtrack",
      returnDirections: false,
    });

    function solveRoute() {
      route.solve(routeUrl, routeParams).then(showRouteInfo);
    }

    function showRouteInfo(routeSolveResult: any) {
      let rings: any = [[]];
      routeSolveResult.routeResults[0].route.geometry.paths.forEach(
        (path: any, index: number) => {
          let tmpPath;
          if (index === 0) {
            tmpPath = [...path];
          } else {
            tmpPath = [...path];
          }
          rings = [rings[0].concat(tmpPath)];
        }
      );
      rings[0].push(
        routeSolveResult.routeResults[0].route.geometry.paths[0][0]
      );

      const geometry: any = {
        type: "polygon",
        rings: rings,
        spatialReference: { wkid: 4326 },
      };

      symbolPolygon.color = [r, g, b, 0.5];

      const existingGeometries: any = routingExtentPolygonGL.graphics
        .toArray()
        .map((x) => {
          if (x.geometry.spatialReference.isWebMercator) {
            return webMercatorUtils.webMercatorToGeographic(x.geometry);
          } else {
            return x.geometry;
          }
        });
      let graphic: any = {
        symbol: symbolPolygon,
        geometry: null,
        attributes: { id: generateGuid() },
      };
      if (existingGeometries.length === 0) {
        graphic.geometry = geometry;
      } else if (existingGeometries.length === 1) {
        const finalPolygon = geometryEngine.difference(
          geometry,
          existingGeometries[0]
        );
        graphic.geometry = finalPolygon;
      } else {
        const union = geometryEngine.union(existingGeometries);
        const finalPolygon = geometryEngine.difference(geometry, union);
        graphic.geometry = finalPolygon;
      }
      if (graphic && graphic.geometry && graphic.geometry.rings) {
        let ringVertices = 0;
        let ringIndex = 0;
        graphic.geometry.rings.forEach((ring: any[], index: number) => {
          if (ring.length > ringVertices) {
            ringIndex = index;
            ringVertices = ring.length;
          }
        });
        graphic.geometry = {
          type: "polygon",
          rings: [graphic.geometry.rings[ringIndex]],
          spatialReference: { wkid: 4326 },
        };
        routingExtentPolygonGL.add(graphic);
        SetServiceAreaPolygons((prev) => {
          const tmpPrev = [...prev];
          tmpPrev.push(graphic);
          return [...tmpPrev];
        });

        let routeGraphic: any = {
          symbol: symbolLine,
          geometry: routeSolveResult.routeResults[0].route.geometry,
        };
        routingPathsGL.add(routeGraphic);
      } else {
        _UndoFailureHandler();
        alert("No service area added");
      }

      setServiceAreaSolved(false);
      setServiceAreaCenterPoint(undefined);
      view.graphics.removeAll();
      setMapclickAction(MAPCLICKACTION.None);
      sketchVM.updateOnGraphicClick = true;
    }
    solveRoute();
  };
  function generateGuid() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  const solveServiceArea = (serviceAreaParams: any) => {
    return serviceArea.solve(serviceAreaUrl, serviceAreaParams).then(
      function (result) {
        if (result.serviceAreaPolygons.features.length) {
          const serviceAreaGraphic: any =
            result.serviceAreaPolygons.features[0];
          serviceAreaGraphic.symbol = symbolPolygon;
          initialServiceAreaGL.add(serviceAreaGraphic);
          view.goTo(serviceAreaGraphic.geometry.extent.expand(2), {});
          if (autoGenerateStops) {
            const stops: any = generateStops(
              serviceAreaGraphic.geometry.extent
            );
            _SovleRoute(stops);
          } else {
            setManualStops([]);
            alert("pls add your route stops");
          }
        }
        setServiceAreaSolved(true);
      },
      function (error) {
        console.log(error);
      }
    );
  };

  const _ServiceArea = async () => {
    const url =
      "https://route-api.arcgis.com/arcgis/rest/services/World/ServiceAreas/NAServer/ServiceArea_World";
    const networkDescription = await networkService.fetchServiceDescription(
      url,
      apiKey
    );
    const travelMode = networkDescription.supportedTravelModes.find(
      (travelMode) => travelMode.name === "Driving Time"
    );
    const geometry = {
      type: "point",
      x: serviceAreaCenterPoint?.x,
      y: serviceAreaCenterPoint?.y,
      spatialReference: view.spatialReference,
    };

    const start = new Graphic({
      geometry: geometry,
      symbol: pointSymbol,
    });

    const serviceAreaParameters = new ServiceAreaParameters({
      apiKey,
      facilities: new FeatureSet({
        features: [start],
      }),
      impedanceAttribute: "travel-time",
      defaultBreaks: [serviceAreaDrivingTime],
      travelMode,
      travelDirection: "from-facility",
      outSpatialReference: view.spatialReference,
    });
    const results = await solveServiceArea(serviceAreaParameters);
    console.log(results);
  };

  const mapContainer = useRef<any>();
  const fileUpload = useRef<any>();

  useEffect(() => {
    if (mapContainer && mapContainer.current) {
      esriConfig.apiKey = apiKey;
      const map = new WebMap({
        basemap: "topo-vector",
        layers: [
          initialServiceAreaGL,
          routingExtentPolygonGL,
          routingPathsGL,
          routingStopsGL,
          serviceAreaCentersGL,
        ],
      });

      const _view = new MapView({
        zoom: 12,
        center: [-84.3995476116046, 33.75127383376601],
        container: mapContainer.current,
        map: map,
      });
      _view.when(() => {
        const _sketchVM = new SketchViewModel({
          view: _view,
          layer: routingExtentPolygonGL,
          defaultUpdateOptions: {
            tool: "reshape",
            enableRotation: false,
            enableScaling: false,
            multipleSelectionEnabled: false,
            toggleToolOnClick: false,
          },
          updateOnGraphicClick: true,
        });
        _sketchVM.on("create", function (event) {
          if (event.state === "complete") {
            SetServiceAreaPolygons((prev: any) => {
              _sketchVM.updateOnGraphicClick = true;
              routingExtentPolygonGL.graphics.removeAt(
                routingExtentPolygonGL.graphics.toArray().length - 1
              );
              let tmpserviceAreaIDToClip: string = "";
              setserviceAreaIDToClip((prev) => {
                tmpserviceAreaIDToClip = prev;
                return "";
              });
              let targetGraphic: any = undefined;
              let targetGraphicIndex = -1;
              prev.forEach((x: any, index: number) => {
                if (
                  x.attributes &&
                  x.attributes.id &&
                  x.attributes.id == tmpserviceAreaIDToClip
                ) {
                  targetGraphic = x;
                  targetGraphicIndex = index;
                }
              });
              if (targetGraphicIndex >= 0) {
                const remainingGraphics = prev.filter(
                  (x: any) =>
                    x.attributes &&
                    x.attributes.id &&
                    x.attributes.id != tmpserviceAreaIDToClip
                );

                const geographicGeometry =
                  webMercatorUtils.webMercatorToGeographic(
                    event.graphic.geometry
                  );

                const remainingVertices: any = [];
                targetGraphic.geometry.rings[0].forEach((x: any) => {
                  const ringVertix = new Point({
                    longitude: x[0],
                    latitude: x[1],
                    spatialReference: { wkid: 4326 },
                  });
                  const contains = geometryEngine.contains(
                    geographicGeometry,
                    ringVertix
                  );
                  if (!contains) {
                    remainingVertices.push(x);
                  }
                });
                const graphicToBeDeleted =
                  routingExtentPolygonGL.graphics.getItemAt(targetGraphicIndex);
                const newGraphic: any = {
                  geometry: {
                    type: "polygon",
                    rings: [remainingVertices],
                    spatialReference: targetGraphic.geometry.spatialReference,
                  },
                  attributes: targetGraphic.attributes,
                  symbol: graphicToBeDeleted.symbol,
                };
                _view.graphics.removeAll();
                routingExtentPolygonGL.graphics.removeAt(targetGraphicIndex);
                routingExtentPolygonGL.graphics.add(newGraphic);
                return remainingGraphics.concat(newGraphic);
              }
              return prev;
            });
          }
        });
        _sketchVM.on("update", function (event) {
          if (event.state === "complete") {
            SetServiceAreaPolygons((prev: any) => {
              const targetGraphic = prev.filter(
                (x: any) =>
                  x.attributes &&
                  x.attributes.id &&
                  x.attributes.id == event.graphics[0].attributes.id
              )[0];
              if (targetGraphic) {
                const remainingGraphics = prev.filter(
                  (x: any) =>
                    x.attributes &&
                    x.attributes.id &&
                    x.attributes.id != event.graphics[0].attributes.id
                );

                const geographicGeometry =
                  webMercatorUtils.webMercatorToGeographic(
                    event.graphics[0].geometry
                  );
                const updatedGraphic = {
                  geometry: geographicGeometry,
                  attributes: targetGraphic.attributes,
                };
                return remainingGraphics.concat(updatedGraphic);
              }
              return prev;
            });
          }
        });
        SetSketchVM(_sketchVM);
        const searchWidget = new Search({
          view: _view,
        });
        _view.ui.add(searchWidget, {
          position: "top-right",
          index: 2,
        });
        let layerList = new LayerList({
          view: _view,
        });
        // Adds widget below other elements in the top left corner of the view
        _view.ui.add(layerList, {
          position: "bottom-left",
        });
        _view.on("click", (event: any) => {
          setMapclickAction((prev: MAPCLICKACTION) => {
            if (prev === MAPCLICKACTION.Pick) {
              pointGraphic.geometry = event.mapPoint;
              setAutoGenerateStops((pAutoGenerateStops) => {
                setServiceAreaCenterPoint((pServiceAreaCenterPoint) => {
                  if (!pAutoGenerateStops && pServiceAreaCenterPoint) {
                    setManualStops((pManualStops) => {
                      const tmpMAnualStops = [...pManualStops];
                      tmpMAnualStops.push(event.mapPoint);
                      return tmpMAnualStops;
                    });
                    pointGraphic.symbol.color = [0, 255, 0];
                    pointGraphic.symbol.outline.color = [255, 255, 0];
                    _view.graphics.add(pointGraphic);
                    return pServiceAreaCenterPoint;
                  } else {
                    pointGraphic.symbol.color = [0, 0, 255];
                    pointGraphic.symbol.outline.color = [255, 255, 0];
                    serviceAreaCentersGL.add(pointGraphic);
                    setServiceAreaSolved(false);
                    setManualStops([]);
                    return event.mapPoint;
                  }
                });
                return pAutoGenerateStops;
              });
            } else if (prev === MAPCLICKACTION.DeleteVertices) {
              const opts = {
                include: routingExtentPolygonGL,
              };
              _view.hitTest(event, opts).then((response) => {
                if (response.results.length) {
                  const result: any = response.results[0];
                  const graphic = result.graphic;
                  setserviceAreaIDToClip(graphic.attributes.id);
                  pointSymbol.color = [234, 117, 0, 1];
                  pointSymbol.outline.color = [255, 0, 0, 0.5];
                  pointSymbol.outline.width = 1;
                  pointSymbol.size = "7px";

                  let geographicGeometry: any = graphic.geometry;
                  if (!graphic.geometry.spatialReference.isWGS84) {
                    geographicGeometry =
                      webMercatorUtils.webMercatorToGeographic(
                        graphic.geometry
                      );
                  }
                  const verticesGraphics = geographicGeometry.rings[0].map(
                    (x: any) => {
                      return {
                        geometry: {
                          type: "point",
                          longitude: x[0],
                          latitude: x[1],
                          spatialReference: 4326,
                        },
                        symbol: pointSymbol,
                      };
                    }
                  );
                  _view.graphics.addMany(verticesGraphics);
                  _sketchVM.create("polygon");
                  console.log(graphic);
                }
              });
              return MAPCLICKACTION.None;
            }
            return prev;
          });
        });
        SetView(_view);
      });
    }
  }, []);
  const btnSolveClickHandler = () => {
    if (!autoGenerateStops && manualStops.length > 3) {
      _SovleRoute(manualStops.concat(manualStops[0]));
    } else if (serviceAreaCenterPoint) {
      _ServiceArea();
    } else {
      alert("Pls specify the service area center");
    }
  };
  const btnDeleteVerticesClickHandler = () => {
    sketchVM.updateOnGraphicClick = false;

    setMapclickAction((prev) => {
      if (prev !== MAPCLICKACTION.DeleteVertices) {
        sketchVM.updateOnGraphicClick = false;
        return MAPCLICKACTION.DeleteVertices;
      } else {
        sketchVM.updateOnGraphicClick = true;
        return MAPCLICKACTION.None;
      }
    });
  };
  const btnPcikClickHandler = () => {
    setMapclickAction((prev: MAPCLICKACTION) => {
      if (prev !== MAPCLICKACTION.Pick) {
        sketchVM.updateOnGraphicClick = false;
        return MAPCLICKACTION.Pick;
      } else {
        sketchVM.updateOnGraphicClick = true;
        return MAPCLICKACTION.None;
      }
    });
  };
  const _BtnResetClickHandler = () => {
    if (window.confirm("Are you sure you want to RESET?") === true) {
      serviceAreaCentersGL.removeAll();
      routingExtentPolygonGL.removeAll();
      routingPathsGL.removeAll();
      routingStopsGL.removeAll();
      initialServiceAreaGL.removeAll();
      setServiceAreaCenterPoint(undefined);
      setMapclickAction(MAPCLICKACTION.None);
      SetServiceAreaPolygons([]);
      setManualStops([]);
      setExtentTolerance(0.1);
      setServiceAreaDrivingTime(10);
      setServiceAreaSolved(false);
      setAutoGenerateStops(true);
    }
  };
  const _UndoFailureHandler = () => {
    const graphicsCount = serviceAreaCentersGL.graphics.length;
    const routingStopsCount = routingStopsGL.graphics.length;
    if (graphicsCount > 0) {
      serviceAreaCentersGL.remove(
        serviceAreaCentersGL.graphics.toArray()[graphicsCount - 1]
      );
      routingStopsGL.removeMany([
        routingStopsGL.graphics.toArray()[routingStopsCount - 1],
        routingStopsGL.graphics.toArray()[routingStopsCount - 2],
        routingStopsGL.graphics.toArray()[routingStopsCount - 3],
        routingStopsGL.graphics.toArray()[routingStopsCount - 4],
      ]);
      initialServiceAreaGL.remove(
        initialServiceAreaGL.graphics.toArray()[graphicsCount - 1]
      );
    }
  };
  const _BtnUndoClickHandler = () => {
    if (serviceAreaPolygons.length > 0) {
      if (window.confirm("Are you sure you want to UNDO?") === true) {
        const graphicsCount = serviceAreaCentersGL.graphics.toArray().length;
        const routingStopsCount = routingStopsGL.graphics.toArray().length;
        if (graphicsCount > 0) {
          setServiceAreaCenterPoint(undefined);
          SetServiceAreaPolygons((prev) => {
            const tmpPrev = prev.slice(0, prev.length - 1);
            return tmpPrev;
          });
          serviceAreaCentersGL.remove(
            serviceAreaCentersGL.graphics.toArray()[graphicsCount - 1]
          );
          routingExtentPolygonGL.remove(
            routingExtentPolygonGL.graphics.toArray()[
              routingExtentPolygonGL.graphics.toArray().length - 1
            ]
          );
          routingPathsGL.remove(
            routingPathsGL.graphics.toArray()[graphicsCount - 1]
          );
          if (manualStops.length > 3) {
            manualStops.forEach((pStop, index) => {
              routingStopsGL.remove(
                routingStopsGL.graphics.toArray()[
                  routingStopsCount - (index + 1)
                ]
              );
            });
          } else {
            routingStopsGL.removeMany([
              routingStopsGL.graphics.toArray()[routingStopsCount - 1],
              routingStopsGL.graphics.toArray()[routingStopsCount - 2],
              routingStopsGL.graphics.toArray()[routingStopsCount - 3],
              routingStopsGL.graphics.toArray()[routingStopsCount - 4],
            ]);
          }

          initialServiceAreaGL.remove(
            initialServiceAreaGL.graphics.toArray()[graphicsCount - 1]
          );
        }
      }
    }
  };
  const download = (pFile: any) => {
    const link = document.createElement("a");
    const url = URL.createObjectURL(pFile);

    link.href = url;
    link.download = pFile.name;
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };
  const _BtnDownloadClickHandler = () => {
    const features: any = [];
    if (serviceAreaPolygons.length > 0) {
      const geojson = {
        crs: {
          type: "name",
          properties: {
            name: "EPSG:4326",
          },
        },
        features: features,
        type: "FeatureCollection",
      };

      serviceAreaPolygons.forEach((polygon, index) => {
        const feature: any = {
          type: "Feature",
          id: index,
          geometry: {
            type: "Polygon",
            coordinates: polygon.geometry.rings,
          },
          properties: {
            OBJECTID: index,
          },
        };
        geojson.features.push(feature);
      });
      const fileContent = JSON.stringify(geojson);
      const file = new File([fileContent], "serviceArea.geojson", {
        type: "application/json",
      });
      download(file);
    }
  };
  const _BtnUploadClickHandler = () => {
    fileUpload.current.click();
  };
  function valuetext(value: number) {
    return `${value * 10}`;
  }
  function _FileUploadChangeHandler(event: any): void {
    if (event.target.files && event.target.files[0]) {
      const reader = new FileReader();
      reader.onload = handleFileLoad;
      reader.readAsText(event.target.files[0]);
    }
  }
  function handleFileLoad(event: any) {
    const data = JSON.parse(event.target.result);
    const importedGraphics = data.features.map((polygon: any, index: any) => {
      const tmpPolygon = new Polygon({
        rings: polygon.geometry.coordinates,
        spatialReference: { wkid: 4326 },
      });
      const graphic: any = {
        geometry: tmpPolygon,
        attributes: {
          id: generateGuid(),
          manual: true,
        },
        symbol: symbolPolygon,
      };
      return graphic;
    });
    routingExtentPolygonGL.addMany(importedGraphics);
    SetServiceAreaPolygons(importedGraphics);
    view.goTo(importedGraphics, {});
  }
  return (
    <div className="App">
      <Card
        sx={{
          position: "fixed",
          minWidth: 275,
          right: "15px",
          bottom: "30px",
          zIndex: 1000,
          width: "325px",
          height: `${autoGenerateStops ? 255 : 200}px`,
        }}
      >
        <CardContent>
          <Stack
            spacing={2}
            direction={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box sx={{ width: 300 }}>
              <Tooltip
                title={
                  mapclickAction === MAPCLICKACTION.Pick
                    ? "Stop adding service area centers"
                    : "Add service area centers"
                }
              >
                <IconButton
                  color={
                    mapclickAction !== MAPCLICKACTION.Pick
                      ? "success"
                      : "warning"
                  }
                  onClick={btnPcikClickHandler}
                  aria-label="Start/Stop"
                >
                  {mapclickAction === MAPCLICKACTION.Pick ? (
                    <HighlightOffOutlinedIcon />
                  ) : (
                    <PlayCircleOutlineIcon />
                  )}
                </IconButton>
              </Tooltip>
              <Tooltip title="Solve">
                <IconButton
                  color="primary"
                  aria-label="Solve"
                  onClick={btnSolveClickHandler}
                  disabled={
                    autoGenerateStops
                      ? serviceAreaCenterPoint == undefined
                      : serviceAreaSolved
                      ? manualStops.length < 4
                      : serviceAreaCenterPoint == undefined
                  }
                >
                  <RouteOutlinedIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={"Delete vertices"}>
                <IconButton
                  color={
                    mapclickAction !== MAPCLICKACTION.DeleteVertices
                      ? "primary"
                      : "warning"
                  }
                  onClick={btnDeleteVerticesClickHandler}
                  aria-label="Delete vertices"
                  disabled={serviceAreaPolygons.length === 0}
                >
                  <HighlightAltIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Undo">
                <IconButton
                  color="primary"
                  aria-label="Undo"
                  onClick={_BtnUndoClickHandler}
                  disabled={
                    serviceAreaPolygons.filter((x) => !x.attributes.manual)
                      .length === 0
                  }
                >
                  <UndoIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Download">
                <IconButton
                  color="primary"
                  aria-label="Download"
                  onClick={_BtnDownloadClickHandler}
                  disabled={serviceAreaPolygons.length === 0}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Upload">
                <IconButton
                  color="primary"
                  aria-label="Upload"
                  onClick={_BtnUploadClickHandler}
                  disabled={serviceAreaPolygons.length > 0}
                >
                  <UploadFileIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Reset">
                <IconButton
                  color="primary"
                  aria-label="Reset"
                  onClick={_BtnResetClickHandler}
                  disabled={serviceAreaPolygons.length === 0}
                >
                  <RestartAltIcon />
                </IconButton>
              </Tooltip>
            </Box>
            {autoGenerateStops ? (
              <Box sx={{ width: 250 }}>
                <Slider
                  aria-label="Extent Tolerance"
                  defaultValue={0.5}
                  step={0.5}
                  marks
                  min={0}
                  max={2.5}
                  valueLabelDisplay="auto"
                  valueLabelFormat={valuetext}
                  onChange={(evt: any) => {
                    if (evt.target) {
                      setExtentTolerance((evt.target.value - 2.5) * -0.1);
                    }
                  }}
                  sx={{ width: 150, left: "5px" }}
                />
              </Box>
            ) : null}
            <Box
              sx={{ width: 250, color: "#4f4c4c", textDecoration: "underline" }}
            >
              {" "}
              <span
                style={{
                  color:
                    serviceAreaPolygons.length === 0 ? "#d32f2f" : "#2e7d32",
                }}
              >
                {serviceAreaPolygons.length}
              </span>{" "}
              Service Area Created
            </Box>
            <Stack
              spacing={2}
              direction={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    defaultChecked
                    checked={autoGenerateStops}
                    onChange={(evt) => {
                      setAutoGenerateStops(evt.currentTarget.checked);
                    }}
                  />
                }
                label="Auto-Generate Stops"
                sx={{
                  flex: 8,
                }}
              />
              <TextField
                id="outlined-number"
                label="Time"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  flex: 4,
                }}
                value={serviceAreaDrivingTime}
                onChange={(evt) => {
                  setServiceAreaDrivingTime(parseFloat(evt.target.value));
                }}
              />
            </Stack>
          </Stack>
        </CardContent>
      </Card>
      <input
        ref={fileUpload}
        type="file"
        name=""
        id="input_file"
        hidden
        onChange={_FileUploadChangeHandler}
      />
      <div ref={mapContainer} style={{ width: "100vw", height: "100vh" }}></div>
    </div>
  );
}

export default App;
